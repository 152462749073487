import React from "react"
import TextBlock from "../components/textBlock"
import Layout from "../components/layout"

const Fourohfour = () => {
  return (
    <Layout>
      <TextBlock
        id="404"
        title="Page not found :/"
        paragraph="..." />
    </Layout>
  )
}

export default Fourohfour
